<template>

  <sub-page>

    <!-- Year Selector -->
    <v-sheet class="pa-4 mt-5 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`">
      <div class="d-flex align-center">
        <ft-select
            v-model="selectedYear"
            :items="yearsSelect" >
          <h4 class="mr-3">
            {{ $t('Year') }} : {{ selectedYear }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h4>
        </ft-select>

        <ft-select
            v-model="selectedMonth"
            :items="monthsSelect" >
          <h4>
            {{ $t('Months') }} : {{ $t(`Month_${selectedMonth}`)  }}
            <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
          </h4>
        </ft-select>
      </div>
    </v-sheet>

    <apexchart type="bar" height="450" :options="totalOptions" :series="totalSeries"></apexchart>
    <apexchart type="bar" height="450" :options="totalOptions" :series="byTimeSeries"></apexchart>
    <apexchart type="treemap" height="550" :options="heatOptions" :series="heatSeries"></apexchart>
  </sub-page>

</template>

<script>
import {mapActions} from "vuex";
import apexchart from "vue-apexcharts";

export default {
  name: "adminSupportStatistics",
  components : {
    apexchart
  },
  data() {
    return {
      response : {},

      selectedYear : "2024",
      selectedMonth : 1,
      date : null,
      years : [],
      months : [],

      totalSeries : [],
      byTimeSeries : [],

      countedSeries : [],
      totalHeaders : [],
      heatSeries : [
      {
        data: [
          {
            x: 'New Delhi',
            y: 218
          },
          {
            x: 'Kolkata',
            y: 149
          },
          {
            x: 'Mumbai',
            y: 184
          },
          {
            x: 'Ahmedabad',
            y: 55
          },
          {
            x: 'Bangaluru',
            y: 84
          },
          {
            x: 'Pune',
            y: 31
          },
          {
            x: 'Chennai',
            y: 70
          },
          {
            x: 'Jaipur',
            y: 30
          },
          {
            x: 'Surat',
            y: 44
          },
          {
            x: 'Hyderabad',
            y: 68
          },
          {
            x: 'Lucknow',
            y: 28
          },
          {
            x: 'Indore',
            y: 19
          },
          {
            x: 'Kanpur',
            y: 29
          }
        ]
      }
    ],

    }
  },
  computed : {
    //chart options
    heatOptions() {
      return  {
        legend: {
          show: false
        },
        chart: {
          height: 350,
              type: 'treemap'
        },
        title: {
          text: 'Basic Treemap'
        }
      }
    },
    totalOptions() {
      return  {
        chart: {
          type: 'bar',
          height: 600,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 2,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          categories: this.twelveMonths,
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      }
    },

    //technical
    yearsSelect() {
      let items = []
      this.years.forEach(el => {
        items.push( { text : el , value : el })
      })

      return items
    },
    monthsSelect() {
      let items = []
      this.months.filter(el=>el.year === this.selectedYear).forEach(el => {
        items.push( { text :  this.$t(`Month_${el.month}`)  , value : el.month })
      })

      return items
    },
    twelveMonths() {
      let months = []
      for (let i=1; i <= 12; i++) {
        months.push(this.$t('Month_' + i))
      }
      return months
    }

  },
  watch : {
    selectedYear() {
      this.initPage()
    },
    selectedMonth() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('support' , ["GET_REQUESTS_STATISTICS"]),

    async initPage() {
      let data = {
        year : this.selectedYear,
        month : this.selectedMonth
      }
      let result = await this.GET_REQUESTS_STATISTICS(data)
      if ( !result ) {
        return this.ERROR
      }

      this.totalSeries = result.total
      this.byTimeSeries = result.total_by_time
      this.response = result

      let typeObject = {}
      result.raw_data.forEach(item => {
        if ( !typeObject[item.type] ) {
          typeObject[item.type] = {}
        }
        typeObject[item.type][item.month] = item.total
      })
      console.log(typeObject)

    },
    initDates() {
      // Get the current date
      const currentDate = new Date();

      const startDate = new Date(2022, 6); // Note: Months are 0-indexed, so 6 represents July.
      const monthsAndYears = [];
      const uniqueYears = [];

      while (startDate <= currentDate) {
        const month = startDate.getMonth() + 1; // Adding 1 to convert to 1-based index
        const year = startDate.getFullYear();

        // Add the month and year to the monthsAndYears array
        monthsAndYears.push({ month: month.toString(), year: year.toString() });

        // Add the year to the uniqueYears array if it's not already there
        if (!uniqueYears.includes(year.toString())) {
          uniqueYears.push(year.toString());
        }

        // Move to the next month
        startDate.setMonth(startDate.getMonth() + 1);

        this.months = monthsAndYears
        this.years = uniqueYears
      }

      this.selectedYear = uniqueYears[uniqueYears.length - 1]
      this.selectedMonth = monthsAndYears[monthsAndYears.length - 1].month

    },
  },
  mounted() {
    this.initDates()
    this.initPage()
  }
}
</script>

<style scoped>

</style>